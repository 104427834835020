<template lang="pug">
  v-card
    .planning-table__row
      .planning-table__row-head
        .planning-table__row-head-cell(
          @click="toggled = !toggled") {{ item.name }}
        .planning-table__row-head-cell.planning-table__row-head-cell--big {{ item.housing_list | liststr('housing_name') }}
        .planning-table__row-head-cell.planning-table__row-head-cell--big {{ item.silages_list | liststr('number') }}
        .planning-table__row-head-cell
          span(:style="{ color: statuses[item.status].color }")
            | {{ statuses[item.status].name | translate }}
          span.planning-table__icon(v-if="!disabled")
            v-btn(
              v-if="'Technologist' === user.user.role"
              icon
              @click="modals.showSettings = true")
              font-awesome-icon(
                size="sm"
                icon="cog")
          .chevron-box.chevron-box--position-right(
            @click="toggled = !toggled")
            v-chevron(:value="toggled")

      .planning-table__row-body(v-if="toggled")
        .planning-table__lock-overlay(v-if="disabled")
        .planning-table__silage
          table.table.table--cell-overflow-hidden
            thead.table__header
              tr.table__row.table__row--header
                th.table__cell {{ 'base.silages' | translate }}
                th.table__cell(v-html="$t('pages.planning.weight_load')")
                th.table__cell {{ 'pages.planning.remnant_silage' | translate }}
            tbody.table__body
              tr.table__row.table__row--body.table__row--no-hover(
                v-for="(silage, index) in item.silages_list"
                :key="index")
                td.table__cell {{ silage.number }}
                td.table__cell
                  v-editable(
                    @change="onSilageUpdate($event, silage)")
                    span {{ silage.planned_weight }}
                td.table__cell {{ silage.net_weight }} {{ 'units.kg' | translate }}

        .planning-table__recipe
          table.table
            thead
              tr.table__row.table__row--header
                th.table__cell
                  .flex
                    span.planning-table__recipe-name.mr-2.text-ellipsis(
                      :title="item.recipe.name") {{ item.recipe.name }}
                    v-info
                      table.table.table--cell-overflow-hidden
                        thead
                          tr.table__row.table__row--header
                            td.table__cell {{ 'base.ingredient' | translate }}
                            td.table__cell % {{ 'base.in_mix' | translate }}
                        tbody.table__body
                          tr.table__row.table__row--body.table__row--no-hover(
                            v-for="(item, index) in item.recipe.proportions"
                            :key="index")
                            td.table__cell(:title="item.ingredient_name") {{ item.ingredient_name }}
                            td.table__cell {{ item.rate }}%
            tbody
              tr.table__row.table__row--body.table__row--no-hover
                td.table__cell
                  v-checkbox(
                    :label="$t('actions.allow_choose_weight')"
                    :value="!!item.change"
                    @input="onStateChange")
      v-dialog(
        max-width="400"
        v-model="modals.showSettings")
        edit-planning-modal(
          :item="item"
          @close="modals.showSettings = false")

      //- INFO: it's for development
      //- v-card-actions
        v-btn(
          danger
          @click="onDelete") Видалити

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { PLANNING_STATUSES } from '@/util/consts.js'

import EditPlanningModal from './modals/EditPlanningModal'

export default {
  name: 'PlanningTableRow',

  props: {
    item: Object,
    disabled: Boolean
  },

  components: {
    EditPlanningModal
  },

  data: () => ({
    toggled: false,
    statuses: PLANNING_STATUSES,
    modals: {
      showSettings: false
    }
  }),

  computed: {
    ...mapGetters([
      'user'
    ])
  },

  methods: {
    ...mapActions([
      'deleteTimetable',
      'updateTimetable'
    ]),

    // onDelete () {
    //   this.deleteTimetable(this.item)
    // },

    onStateChange (value) {
      this.item.change = value ? 1 : 0
      this.$emit('change', this.item)
    },

    onSilageUpdate (value, silage) {
      silage.planned_weight = +value
      const payload = {
        id: this.item.id,
        data: {
          ration_id: this.item.ration_id,
          silages_list: [
            {
              id: silage.id,
              planned_weight: +value
            }
          ]
        }
      }
      this.updateTimetable(payload)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .planning-table__row {

    &-head {
      padding: .8rem 1.5rem;
      position: relative;
      display: flex;
      gap: 8px;
      align-items: center;
      height: 4rem;

      &-cell {
        padding-right: 1rem;
        width: 17%;
        max-width: 25rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:last-child {
          padding-right: 0;
        }

        &--big {
          width: 33%;
        }
      }
    }

    &-body {
      position: relative;
      display: flex;
      justify-content: space-between;
      border-top: .6rem solid $color-gray-light-2;

      .table__row--header {
        .table__cell {
          padding: 1rem 1.2rem;
          height: 5.6rem;
        }
      }
    }
  }

  .planning-table__silage {
    width: calc(60% - 2rem);
    overflow: hidden;
    border-bottom-left-radius: 1rem;
  }

  .planning-table__recipe {
    width: calc(40% - 2rem);
  }

  .planning-table__icon {
    cursor: pointer;
    position: absolute;
    right: 3.5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .planning-table__recipe-name {
    color: $color-green-light-1 !important;
  }
</style>
